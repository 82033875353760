<template>
    <div>
        <header
            id="masthead"
            class="cryout"
            itemscope=""
            itemtype="http://schema.org/WPHeader"
            role="banner"
        >
            <div id="site-header-main" :class="fixHeader">
                <div id="site-header-main-inside">
                    <nav id="mobile-menu" :class="{ active: navOpen }">
                        <span id="nav-cancel" @click="navOpen = false">
                                             <v-icon
											 color="primary"
											 size="45"
                                             id="nav-cancel" 
											 @click="navOpen = false"
											 >mdi-close</v-icon>
                        </span>
                        <div>
                            <ul id="mobile-nav" class="">
                                <li
                                    v-for="item in menu"
                                    :key="item.path"
                                    @click="navOpen = false"
                                >
                                    <router-link
                                        :to="item.path"
                                        class="d-block"
                                    >
                                        <span>{{ item.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <!-- #mobile-menu -->

                    <div id="branding">
                        <div class="identity">
                            <router-link
                                to="/"
                                id="logo"
                                class="custom-logo-link"
                                title=""
                                rel="home"
                            >
                                <img
                                    src="../assets/img/logo.png"
                                    class="custom-logo"
                                    alt=""
                                />
                            </router-link>
                        </div>
                        <div id="site-text">
                            <h1 itemprop="headline" id="site-title">
                                <span>
                                    <a
                                        href="https://vksp.ru"
                                        title="Ваш Кредитный Специалист"
                                        rel="home"
                                    ></a>
                                </span>
                            </h1>
                            <span id="site-description" itemprop="description"
                                >Ваш Кредитный Специалист</span
                            >
                        </div>
                    </div>
                    <!-- #branding -->
                    <a id="nav-toggle" href="#" @click="navOpen = true">
                                             <v-icon
											 color="primary"
											 size="45"
											 >mdi-menu</v-icon>
                    </a>
                    <nav
                        id="access"
                        role="navigation"
                        aria-label="Primary Menu"
                        itemscope=""
                        itemtype="http://schema.org/SiteNavigationElement"
                    >
                        <div>
                            <ul id="prime_nav" class="">
                                <li v-for="item in menu" :key="item.path">
                                    <router-link :to="item.path">
                                        <span>{{ item.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <!-- #access -->
                </div>
                <!-- #site-header-main-inside -->
            </div>
            <!-- #site-header-main -->
            <div id="header-image-main">
                <div id="header-image-main-inside"></div>
                <!-- #header-image-main-inside -->
            </div>
            <!-- #header-image-main -->
        </header>
        <!-- #masthead -->

        <div id="content" class="cryout">
            <main id="main" role="main" class="main">
                <router-view />
            </main>
        </div>
        <footer
            id="footer"
            class="cryout"
            role="contentinfo"
            itemscope=""
            itemtype="http://schema.org/WPFooter"
        >
            <div id="footer-inside">
                <div id="site-copyright sec-title">
                   <p>©2024 Ваш кредитный специалист</p>
				   <a
				   href="/privacy.docx"
				   download="Privacy.docx"
				   >Политика конфедециальности</a>
                </div>
				
                <div class="text-caption footer-text font-weight-regular">
                    Кредит предоставляют банки-партнеры: ПАО «Почта Банк».
                    Лицензия ЦБ РФ № 650 от 09.04.2020 г. АО «ОТП Банк».
                    Лицензия ЦБ РФ № 2766 от 27.11.2014 г. ООО МФК «ОТП Финанс».
                    Лицензия ЦБ РФ № 65-15-030-45-006297 от 16.02.2015 г. АО
                    «Банк Русский Стандарт». Лицензия ЦБ РФ № 2289 от 19.11.2014
                    г. АО «Тинькофф Банк». Лицензия ЦБ РФ № 2673 от 24.03.2015
                    г. ПАО «МТС-Банк». Лицензия ЦБ РФ № 2268 от 17.12.2014 г. АО
                    «Альфа-Банк». Лицензия ЦБ РФ №1326 от 16.01.2015 г. ПАО
                    «Совкомбанк». Лицензия ЦБ РФ № 963 от 05.12.2014 г. При
                    участии ООО «НЕОТЕХ» (ОГРН: 1227700121758, ИНН: 9703076320,
                    зарегистрировано по адресу: 123022, город Москва, 1-Й Земельный пер, д. 1).
                </div>
                <div
                    style="
                        display: block;
                        float: right;
                        clear: right;
                        font-size: 0.9em;
                    "
                >
                    Powered by Banana Studio
                </div>
            </div>
            <!-- #footer-inside -->
        </footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            navOpen: false,
            fixHeader: "",
            menu: [
                {
                    name: "Главная",
                    path: "/",
                },
                {
                    name: "Сотрудничество",
                    path: "/cooperation",
                },
                {
                    name: "POS",
                    path: "/pos",
                },
                {
                    name: "Онлайн кредит",
                    path: "/credit",
                },
                {
                    name: "Контакты",
                    path: "/contacts",
                },
            ],
        };
    },
    mounted() {
        window.addEventListener("scroll", this.offsetTop);
        this.offsetTop();
    },
    methods: {
        offsetTop() {
            let top = document.body.getBoundingClientRect().top;
            if (top < -300) {
                this.fixHeader = "header-fixed";
            } else {
                this.fixHeader = "";
            }
        },

    },
};
</script>

<style>
.info {
    position: relative;
}

.infotext {
    margin: auto;
    max-width: 1600px;
}
</style>
