var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"imagePicker d-flex flex-column h-100"},[(_vm.label.length)?_c('p',{staticClass:"text-subtitle-2 photo-label black--text mb-2 mt-auto"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticStyle:{"position":"relative"},style:(_vm.pb ? 'padding-bottom: 52px;' : ''),on:{"click":_vm.getDisabledStatus}},[_c('image-input',{attrs:{"disabled":"disabled"},on:{"input":function($event){return _vm.onFileChange($event)}}},[_c('div',{attrs:{"slot":"activator"},slot:"activator"},[(!_vm.value.length)?_c('div',[_vm._t("default")],2):_c('div',{attrs:{"wrap":""}},_vm._l((_vm.value),function(avatar){return _c('v-flex',{key:avatar.key},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',[_c('v-img',{class:{ 'img-hover': hover },attrs:{"height":_vm.height,"src":avatar.imageURL,"aspect-ratio":_vm.ratio.w / _vm.ratio.h}}),(avatar.uploaded)?_c('v-progress-linear',{attrs:{"value":100,"color":"success"}}):(
                                        _vm.activeImageUploads.state != 'EMPTY'
                                    )?[(
                                            _vm.activeImageUploads.state ===
                                            _vm.imageUploadingStates.NEW
                                        )?_c('v-progress-linear',{attrs:{"indeterminate":true,"height":"8"}}):(
                                            _vm.activeImageUploads.state ===
                                            _vm.imageUploadingStates.UPLOADING
                                        )?_c('v-progress-linear',{attrs:{"value":_vm.activeImageUploads.progress,"height":"8"}}):(
                                            _vm.activeImageUploads.state ===
                                            _vm.imageUploadingStates.ERROR
                                        )?_c('v-progress-linear',{attrs:{"value":_vm.activeImageUploads.progress,"color":"error","height":"8"}}):(
                                            _vm.activeImageUploads.state ===
                                            _vm.imageUploadingStates.COMPLETE
                                        )?_c('v-progress-linear',{attrs:{"value":_vm.activeImageUploads.progress,"color":"success","height":"8"}}):_vm._e()]:_vm._e()],2)]}}],null,true)})],1)}),1)])])],1),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.camera),callback:function ($$v) {_vm.camera=$$v},expression:"camera"}},[_c('v-card',{staticClass:"pa-5"},[(_vm.camera)?_c('MulticorderUI',{on:{"photo":function($event){return _vm.onFileChange($event)}}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.camera = false}}},[_vm._v("Назад")])],1)],1),(_vm.webcam)?[(_vm.webcamIcon)?_c('v-btn',{staticClass:"mx-auto",attrs:{"icon":"","x-large":""},on:{"click":function($event){_vm.camera = true}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-camera ")])],1):_c('v-btn',{staticClass:"w-100 mt-2",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.camera = true}}},[_vm._v("Фото с веб-камеры")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }