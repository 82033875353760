<template>
    <div class="multicorder_ui text-center">
        <div class="videobox">
            <Multicorder
                :video-source="videoSource"
                @error="onError"
                @cameras="onCameras"
                @video-live="onVideoLive"
                @view-change="onViewChange"
                @photo="emitPhoto($event)"
                ref="multicorder"
                :videoTypes="videoTypes"
                height="400"
            />
        </div>
        <v-select
            v-show="view == 'video'"
            :items="videoSourceList"
            :return-object="true"
            v-model="videoSource"
            label="Выберете веб-камеру"
            class="mt-2"
        />
        <!-- <div
    v-if="videoSource"
    align="right"
    background-color="black"
    class="grey--text videobox mb-5"
  >
    {{ videoSource.text }}
    <v-icon @click="videoClose" color="grey">mdi-close-circle</v-icon>
  </div> -->
        <v-row v-show="view == 'video' && videoSource != null" justify="center">
            <v-col cols="auto" align="center">
                <v-icon x-large @click="videoSnapshot(view)" color="teal"
                    >mdi-camera</v-icon
                >
            </v-col>
        </v-row>
        <v-row v-show="view == 'snapshot'">
            <v-col>
                <v-btn
                    class="mx-2"
                    @click="closeSnapshot()"
                    fab
                    mdi-icon
                    x-small
                    light
                    ><v-icon x-large color="red"
                        >mdi-close-circle</v-icon
                    ></v-btn
                >
                <v-btn
                    class="mx-2"
                    @click="acceptSnapshot()"
                    fab
                    mdi-icon
                    x-small
                    light
                    ><v-icon color="success" size="25"
                        >mdi-check-bold</v-icon
                    ></v-btn
                >
            </v-col>
        </v-row>
    </div>
</template>

<script>
/**
 * For Hot reload load the `*.vue` files from the parent `/src/` directly.
 */
import Multicorder from "./components/multicorder";
// import { Multicorder } from "../../../src/lib-components/index.js";

export default {
    name: "MulticorderUI",
    components: {
        Multicorder,
    },
    props: {
        videoTypes: {
            type: Array,
            default: () => {
                return ["camera"];
            },
        },
    },
    data() {
        return {
            controls: null,
            videoSource: null,
            videoSourceList: [],
            view: "video",
            snapshot: null,
        };
    },
    methods: {
        onError(error) {
            console.log("Error emitted", error);
        },
        onCameras(cameras) {
            // console.log("Available cameras", cameras);
            this.videoSourceList =
                this.$refs.multicorder.listFromCameras(cameras);
            this.videoSource =
                this.$refs.multicorder.listFromCameras(cameras)[0];
        },
        onVideoLive() {
            this.controls = "liveVideo";
        },
        onViewChange(view) {
            this.view = view;
        },
        videoSnapshot(fromView) {
            this.$refs.multicorder.videoSnapshot(fromView);
        },
        acceptSnapshot() {
            this.$emit("photo", this.snapshot);
            this.closeSnapshot();
        },
        emitPhoto(photo) {
            this.snapshot = photo;
        },
        videoClose() {
            this.$refs.multicorder.stopVideo();
            this.view = "video";
            this.controls = "liveVideo";
            this.videoSource = null;
        },
        closeSnapshot() {
            this.$refs.multicorder.closeSnapshot();
        },
        snapshotDownload() {
            this.$refs.multicorder.downloadSnapshot();
        },
    },
};
</script>

<style scoped>
.videobox {
    background-color: black;
}
</style>
