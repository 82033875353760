import Vue from "vue";
import VueRouter from "vue-router";
import App from "../views/App.vue";
import AppFinal from "../views/AppFinal.vue";
import Home from "../views/Home.vue";
import Cooperation from "../views/Cooperation.vue";
import Contacts from "../views/Contacts.vue";
import Credit from "../views/Credit.vue";
import CreditFinal from "../views/CreditFinal.vue";
import Agro from "../views/Agro.vue";
import AgroFinal from "../views/AgroFinal.vue";
import NotFound from "../views/NotFound.vue";
import Pos from "../views/Pos.vue";
import Go from "../views/Go.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { layout: "withBanner" },
    },
    {
        path: "/cooperation",
        name: "Cooperation",
        component: Cooperation,
        meta: { layout: "main" },
    },
    {
        path: "/credit/:id?",
        name: "Credit",
        component: Credit,
        meta: { layout: "main" },
    },
    {
        path: "/agro/:id?",
        name: "Agro",
        component: Agro,
        meta: { layout: "main" },
    },
    {
        path: "/app/:id?",
        name: "App",
        component: App,
        meta: { layout: "main" },
    },
    {
        path: "/creditfinal",
        name: "CreditFinal",
        component: CreditFinal,
        meta: { layout: "main" },
    },
    {
        path: "/appfinal",
        name: "AppFinal",
        component: AppFinal,
        meta: { layout: "main" },
    },
    {
        path: "/agrofinal",
        name: "AgroFinal",
        component: AgroFinal,
        meta: { layout: "main" },
    },
    {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
        meta: { layout: "main" },
    },
    {
        path: "/pos",
        name: "Pos",
        component: Pos,
        meta: { layout: "main" },
    },
    {
        path: "/go",
        name: "Go",
        component: Go,
    },
    { 
    path: '/404', 
    name: '404', 
    component: NotFound, 
	meta: { layout: "main" },
    }, { 
    path: '*',
    redirect: '/404' 
  },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;
