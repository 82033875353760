<template>
    <div class="credit lp-blocks">
        <div class="credit__container">
            <p class="text-h6 pt-5 mb-1">
                                            <v-icon
											 color="green"
											 size="30"
											 class="mb-2"
											 >mdi-shield-check</v-icon>
			Заявка на кредит №{{ creditId }}</p>
            <v-divider></v-divider>
            <div class="credit__calc mt-3">
                <v-row class="justify-space-between">
                    <v-col cols="12" class="credit__content">
                        <transition name="fade" mode="out-in">
                            <div class="appStep">
                                <v-form ref="form" lazy-validation>
                                    <p
                                        class="
                                            text-h5
                                            font-weight-bold
                                            greyBold--text
                                            mb-4
                                        "
                                    >
                                        Фото
                                    </p>
                                    <v-alert class="alert-primary">
                                            <v-icon
											 color="red"
											 size="21"
											 class="mb-1"
											 >mdi-alert-box</v-icon>
                                        К рассмотрению допускаются фото без бликов и с читаемым текстом      
                                    </v-alert>
                                    <v-alert class="alert-primary">
                                            <v-icon
											 color="red"
											 size="21"
											 class="mb-1"
											 >mdi-alert-box</v-icon>
											 Для доступа к камере вашего устройства необходимо предоставить разрешения
                                    </v-alert>
                                    <div class="mt-3 mb-5">
                                        <images-upload
                                            :types="types"
                                            @checkUpload="checkUpload = $event"
                                            :id="$route.params.id"
                                            method="step"
                                            @input="uploadHandler"
                                            :classesRow="['align-start']"
                                            :validated="isValidated"
                                            :uploadImages="docs"
                                        ></images-upload>
                                    </div>

                                    <p
                                        class="
                                            text-h5
                                            font-weight-bold
                                            greyBold--text
                                            mb-4
                                        "
                                    >
                                        Анкета
                                    </p>
                                    <v-row
                                        class="align-center"
                                        :class="{
                                            'mb-3':
                                                spouse_data.marital_status ==
                                                    1 ||
                                                main_data.employment_status ==
                                                    1 ||
                                                main_data.employment_status ==
                                                    2 ||
                                                main_data.employment_status ==
                                                    3,
                                        }"
                                    >
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="4"
                                            class="app_input"
                                        >
                                            <validation-provider
                                                style="width: 100%"
                                                v-slot="{ errors }"
                                                name="Телефон заемщика"
                                                mode="aggressive"
                                                :rules="{
                                                    required: true,
                                                    regex: '^\\+7\\s\\(\\9\\d{2}\\)\\s\\d{3}-\\d{2}-\\d{2}$',
                                                }"
                                            >
                                                <v-text-field
                                                    :rules="[(v) => !!v]"
                                                    @input="
                                                        (val) =>
                                                            inputPhone(
                                                                val,
                                                                'main_data.phone'
                                                            )
                                                    "
                                                    inputmode="tel"
                                                    type="phone"
                                                    v-model="main_data.phone"
                                                    v-mask="
                                                        '+7 (###) ###-##-##'
                                                    "
                                                    outlined
                                                    :error-messages="errors"
                                                    label="Телефон заемщика"
                                                    placeholder="Телефон заемщика"
                                                    class="font-weight-medium"
                                                    required
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="4"
                                            class="app_input"
                                        >
                                        <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="СНИЛС"
                                                    rules="length:14"
                                        >
                                            <v-text-field
                                                v-mask="'###-###-### ##'"
                                                v-model="snils"
                                                inputmode="numeric"
                                                outlined
												:error-messages="errors"
                                                label="СНИЛС (обязательно от 200 тыс. ₽)"
                                                placeholder="СНИЛС (обязательно от 200 тыс. ₽)"
                                                class="font-weight-medium"
                                                @input="
                                                    (val) =>
                                                        removeFocus(val, 14)
                                                "
                                            ></v-text-field>
										  </validation-provider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="4"
                                            class="app_input"
                                        >
                                            <validation-provider
											    v-slot="{ errors }"
                                                style="width: 100%"
                                                name="Статус занятости"
                                                mode="aggressive"
                                                rules="required"
                                            >
                                            <v-select
											    class="font-weight-medium"
												:rules="[(v) => !!v]"
											    required
											    id="employment_status"
                                                :items="employment_statuses"
												item-value="id"
												item-text="name"
                                                v-model="main_data.employment_status"
                                                label="Статус занятости"
												:error-messages="errors"
												outlined
                                            />
											</validation-provider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="6"
                                            class="app_input"
                                        >
                                            <validation-provider
											    v-slot="{ errors }"
                                                style="width: 100%"
                                                name="Образование"
                                                mode="aggressive"
                                                rules="required"
                                            >
                                            <v-select
												:rules="[(v) => !!v]"
												class="font-weight-medium"
											    required
                                                :items="education"
												item-text="name"
												item-value="id"
                                                v-model="main_data.education"
                                                label="Образование"
												:error-messages="errors"
												outlined
                                            />
											</validation-provider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="6"
                                            class="app_input"
                                        >
                                            <validation-provider
											    v-slot="{ errors }"
                                                style="width: 100%"
                                                name="Семейное положение"
                                                mode="aggressive"
                                                rules="required"
                                            >
                                                <v-select
												    class="font-weight-medium"
												    :rules="[(v) => !!v]"
													required
                                                    :items="marital_statuses"
													item-value="id"
													item-text="name"
                                                    v-model="spouse_data.marital_status"
                                                    label="Семейное положение"
													outlined
													:error-messages="errors"
                                                />
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <div
                                        v-if="
                                            main_data.employment_status == 1 ||
                                            main_data.employment_status == 2
                                        "
                                    >
                                        <v-row class="align-center mb-3">
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                                ref="employerField"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Наименование работодателя"
                                                    mode="aggressive"
                                                    rules="required"
                                                >
                                                    <v-combobox
                                                        :rules="[(v) => !!v]"
                                                        v-model="employment_data.employer"
                                                        :error-messages="errors"
                                                        label="Наименование работодателя"
                                                        placeholder="Наименование работодателя"
                                                        :items="currentCompany"
                                                        outlined
                                                        class="font-weight-medium"
                                                        no-filter
                                                        @click="addTopField"
                                                        @change="setCompany"
                                                        @blur="setCompany"
                                                        @update:search-input="getAddressHandler"
                                                    >
                                                        <template
                                                            v-slot:item="{
                                                                item,
                                                            }"
                                                        >
                                                            <div class="py-2">
                                                                {{ item.name }}
                                                                <p
                                                                    class="
                                                                        text-caption
                                                                        mb-0
                                                                    "
                                                                >
                                                                    {{
                                                                        item.address
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </template>
                                                    </v-combobox>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    name="Адрес работодателя"
                                                    v-slot="{ errors }"
                                                    mode="aggressive"
                                                    rules="required"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        outlined
                                                        required
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        label="Адрес работодателя"
                                                        placeholder="Адрес работодателя"
                                                        v-model="
                                                            employment_data.employer_address
                                                        "
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
											    v-if="main_data.employment_status === 1"
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Должность"
                                                    mode="aggressive"
                                                    rules="required"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        outlined
                                                        required
                                                        class="font-weight-medium"
                                                        label="Должность"
                                                        placeholder="Должность"
                                                        :error-messages="errors"
                                                        v-model="employment_data.position"
                                                    >
                                                    </v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Год трудоустройства"
                                                    mode="aggressive"
                                                    :rules="{
                                                        regex: '^(\\d{4})?$',
                                                        required: true,
                                                    }"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        v-model="
                                                            employment_data.employment_start
                                                        "
                                                        inputmode="numeric"
                                                        outlined
                                                        v-mask="'####'"
                                                        :error-messages="errors"
                                                        label="Год трудоустройства"
                                                        placeholder="Год трудоустройства"
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        required
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Заработная плата"
                                                    mode="aggressive"
                                                    :rules="{
                                                        regex: '^(\\d{0,}(\\.)?\\d{0,2})?$',
                                                        required: true,
                                                    }"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        v-model="
                                                            employment_data.salary
                                                        "
                                                        inputmode="numeric"
                                                        outlined
                                                        :error-messages="errors"
                                                        label="Заработная плата"
                                                        placeholder="Заработная плата"
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        required
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Рабочий телефон"
                                                    mode="aggressive"
                                                    :rules="{
                                                        required: true,
                                                        regex: '^\\+7\\s\\(\\d{3}\\)\\s\\d{3}-\\d{2}-\\d{2}$',
                                                    }"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        @input="
                                                            (val) => {
                                                                inputPhone(
                                                                    val,
                                                                    'employment_data.employer_phone'
                                                                );
                                                                removeFocus(
                                                                    val,
                                                                    18
                                                                );
                                                            }
                                                        "
                                                        inputmode="tel"
                                                        type="phone"
                                                        v-model="
                                                            employment_data.employer_phone
                                                        "
                                                        v-mask="
                                                            '+7 (###) ###-##-##'
                                                        "
                                                        outlined
                                                        :error-messages="errors"
                                                        label="Рабочий телефон"
                                                        placeholder="Рабочий телефон"
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        required
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div
                                        v-if="main_data.employment_status == 3"
                                    >
                                        <v-row class="align-center mb-3">
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="6"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Доход"
                                                    mode="aggressive"
                                                    :rules="{
                                                        regex: '^(\\d{0,}(\\.)?\\d{0,2})?$',
                                                        required: true,
                                                    }"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        v-model="
                                                            pension_data.pension_salary
                                                        "
                                                        inputmode="numeric"
                                                        outlined
                                                        :error-messages="errors"
                                                        label="Доход"
                                                        placeholder="Доход"
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        required
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="6"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Дата выхода на пенсию"
                                                    mode="aggressive"
                                                    rules="required|length:10"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        v-mask="'##.##.####'"
                                                        inputmode="numeric"
                                                        outlined
                                                        required
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        label="Дата выхода на пенсию"
                                                        placeholder="Дата выхода на пенсию"
                                                        :error-messages="errors"
                                                        v-model="
                                                            pension_data.pension_date
                                                        "
                                                        @input="
                                                            (val) =>
                                                                removeFocus(
                                                                    val,
                                                                    10
                                                                )
                                                        "
                                                    >
                                                    </v-text-field>
                                                </validation-provider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-if="spouse_data.marital_status == 1">
                                        <v-row class="align-center mb-3">
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="ФИО супруга"
                                                    mode="aggressive"
                                                    rules="required"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        outlined
                                                        required
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        label="ФИО супруга"
                                                        placeholder="ФИО супруга"
                                                        v-model="
                                                            spouse_data.spouse_fio
                                                        "
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Дата рождения супруга"
                                                    mode="aggressive"
                                                    rules="required"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        v-mask="'##.##.####'"
                                                        inputmode="numeric"
                                                        outlined
                                                        required
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        label="Дата рождения супруга"
                                                        placeholder="Дата рождения супруга"
                                                        :error-messages="errors"
                                                        v-model="
                                                            spouse_data.spouse_birthdate
                                                        "
                                                    >
                                                    </v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                class="app_input"
                                            >
                                                <validation-provider
                                                    style="width: 100%"
                                                    v-slot="{ errors }"
                                                    name="Телефон супруга"
                                                    mode="aggressive"
                                                    :rules="{
                                                        required: true,
                                                        regex: '^\\+7\\s\\(\\9\\d{2}\\)\\s\\d{3}-\\d{2}-\\d{2}$',
                                                    }"
                                                >
                                                    <v-text-field
                                                        :rules="[(v) => !!v]"
                                                        @input="
                                                            (val) =>
                                                                inputPhone(
                                                                    val,
                                                                    'spouse_data.spouse_phone'
                                                                )
                                                        "
                                                        type="phone"
                                                        v-model="
                                                            spouse_data.spouse_phone
                                                        "
                                                        inputmode="tel"
                                                        v-mask="
                                                            '+7 (###) ###-##-##'
                                                        "
                                                        outlined
                                                        :error-messages="errors"
                                                        label="Телефон супруга"
                                                        placeholder="Телефон супруга"
                                                        class="
                                                            font-weight-medium
                                                        "
                                                        required
                                                    ></v-text-field>
                                                </validation-provider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-row class="align-center mb-3">
                                        <v-col
                                            cols="12"
                                            md="4"
                                            class="
                                                d-flex
                                                align-center
                                                app_input
                                            "
                                        >
                                            <validation-provider
											    v-slot="{ errors }"
                                                style="width: 100%"
                                                name="Контактное лицо"
                                                mode="aggressive"
                                                rules="required"
                                            >
                                            <v-select
											    class="font-weight-medium"
												:rules="[(v) => !!v]"
											    required
                                                :items="contacts"
                                                v-model="contact_data.contact"
												:error-messages="errors"
												item-value="id"
                                                item-text="name"
                                                label="Контактное лицо"
												outlined
                                            />
											 </validation-provider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="4"
                                            class="app_input"
                                        >
                                            <validation-provider
                                                style="width: 100%"
                                                v-slot="{ errors }"
                                                name="ФИО контактного лица"
                                                mode="aggressive"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    :rules="[(v) => !!v]"
                                                    v-model="
                                                        contact_data.contact_fio
                                                    "
                                                    outlined
                                                    :error-messages="errors"
                                                    label="ФИО контактного лица"
                                                    placeholder="ФИО контактного лица"
                                                    class="font-weight-medium"
                                                    required
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="4"
                                            class="app_input"
                                        >
                                            <validation-provider
                                                style="width: 100%"
                                                v-slot="{ errors }"
                                                name="Телефон контактного лица"
                                                mode="aggressive"
                                                :rules="{
                                                    required: true,
                                                    regex: '^\\+7\\s\\(\\9\\d{2}\\)\\s\\d{3}-\\d{2}-\\d{2}$',
                                                }"
                                            >
                                                <v-text-field
                                                    :rules="[(v) => !!v]"
                                                    @input="
                                                        (val) =>
                                                            inputPhone(
                                                                val,
                                                                'contact_data.contact_phone'
                                                            )
                                                    "
                                                    inputmode="tel"
                                                    type="phone"
                                                    v-model="
                                                        contact_data.contact_phone
                                                    "
                                                    v-mask="
                                                        '+7 (###) ###-##-##'
                                                    "
                                                    outlined
                                                    :error-messages="errors"
                                                    label="Телефон контактного лица"
                                                    placeholder="Телефон контактного лица"
                                                    class="font-weight-medium"
                                                    required
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-checkbox
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Необходимо принять условия',
                                        ]"
                                        v-model="accept"
                                        class="
                                            font-weight-medium
                                            mt-4
                                            text-lh-lg
                                        "
                                    >
                                        <template v-slot:label>
                                            <div class="text-subtitle-2">
                                                <span>Я соглашаюсь </span>
                                                <span
                                                    class="success--text"
                                                    @click="
                                                        openData = true;
                                                        accept = !accept;
                                                    "
                                                    >с условиями обработки
                                                    персональных данных</span
                                                >
                                                <span> и </span>
                                                <span
                                                    class="success--text"
                                                    @click="
                                                        openParam = true;
                                                        accept = !accept;
                                                    "
                                                    >параметрами
                                                    потребительского
                                                    кредитования.</span
                                                >
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <div class="error-message">
                                        {{ resultMessage }}
                                    </div>
                                    <div
                                        class="d-flex justify-center aling-center"
                                        :class="
                                            sm
                                                ? 'flex-column mt-2'
                                                : 'align-center mt-5'
                                        "
                                    >
                                        <v-btn
                                            color="primary"
                                            :class="{ 'mt-5': sm }"
                                            x-large
                                            @click="sendForm"
                                        >
                                            Отправить
                                        </v-btn>
                                    </div>
                                </v-form>
                            </div>
                        </transition>
                    </v-col>
                </v-row>
            </div>
            <div class="preloader" v-if="isFetching">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
            <v-dialog v-model="openData" max-width="900">
                <v-card :class="sm ? 'pa-5' : 'pa-15'">
                    <v-btn icon class="closeModal" @click="openData = false">
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
                    <div
                        class="d-flex align-center justify-center"
                        :class="{ 'flex-wrap': sm }"
                    >
                        <img
                            src="../assets/img/logo.png"
                            class="custom-logo mr-5"
                            alt=""
                        />
                        <p class="text-h5" :class="{ 'mt-5': sm }">
                            Я ПОДТВЕРЖДАЮ СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ
                            ДАННЫХ
                        </p>
                    </div>
                    <p class="text-subtitle-2 mt-5">
                        {{ dataText }}
                    </p>
                </v-card>
            </v-dialog>
            <v-dialog v-model="openParam" max-width="700">
                <v-card :class="sm ? 'pa-5' : 'pa-10'">
                    <v-btn icon class="closeModal" @click="openParam = false">
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
                    <div
                        class="d-flex align-center flex-wrap"
                        style="padding-right: 20px"
                    >
                        <p class="text-h5">
                            Параметры потребительского кредитования
                        </p>
                    </div>
                    <v-row class="text-subtitle-2 mt-5 justify-space-between">
                        <v-col cols="6">
                            <p><b class="mb-5 font-weight-bold">Кому подходит</b></p>
                            <ul class="pa-0">
                                <li class="mt-2">— гражданам России</li>
                                <li class="mt-2">— с действующим паспортом</li>
                                <li class="mt-2">— постоянной пропиской</li>
                                <li class="mt-2">— постоянным доходом</li>
                            </ul>
                        </v-col>
                        <v-col cols="6">
                            <p><b class="mb-5 font-weight-bold text-center">Что обязательно</b></p>
                            <ul class="pa-0">
                                <li class="mt-2">
                                    — наличие номера мобильного телефона
                                </li>
                                <li class="mt-2">
                                    — работающие граждане указывают рабочий
                                    телефон
                                </li>
                                <li class="mt-2">
                                    — неработающие пенсионеры дополнительно
                                    указывают домашний или другой контактный
                                    номер телефона
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { length } from 'vee-validate/dist/rules'
import { required, regex } from "vee-validate/dist/rules";
import { DATAPROT } from "../dataJson/dataProt";
import ImagesUpload from "./ImagesUpload.vue";
import axios from "axios";

extend("required", {
    ...required,
    message: "Поле не может быть пустым",
});

extend("regex", {
    ...regex,
    message: "Неверный формат поля",
});
extend('length', length);
export default {
    components: {
        ValidationProvider,
        ImagesUpload,
    },
    data() {
        return {
            creditId: null,
            employment_statuses: [
                { id: 1, name: "Работающий" },
                { id: 2, name: "Индивидуальный предприниматель" },
                { id: 3, name: "Пенсионер" },
            ],
            education: [
                { id: 1, name: "Среднее" },
                { id: 2, name: "Средне-специальное" },
                { id: 3, name: "Неоконченное высшее" },
                { id: 4, name: "Высшее" },
            ],
            marital_statuses: [
                { id: 1, name: "Женат/Замужем" },
                { id: 2, name: "Холост" },
                { id: 3, name: "Разведен/Разведена" },
                { id: 4, name: "Вдовец/Вдова" },
            ],
            contacts: [
                { id: 1, name: "Брат" },
                { id: 2, name: "Сестра" },
                { id: 3, name: "Друг" },
                { id: 4, name: "Коллега" },
                { id: 5, name: "Родственник" },
            ],
            types: [
                {
                    title: "Паспорт: разворот, 2-3 стр.",
                    key: "scan1",
                },
                {
                    title: "Паспорт: действ. регистрация",
                    key: "scan2",
                },
                {
                    title: "Фото заемщика",
                    key: "photo",
                },
            ],
            docs: [],
            main_data: {
                phone: null,
                employment_status: null,
                education: null,
            },
            contact_data: {
                contact: null,
                contact_fio: null,
                contact_phone: null,
            },
            employment_data: {
                employer: null,
                employer_address: null,
                position: null,
                employment_start: null,
                salary: null,
                employer_phone: null,
            },
            pension_data: {
                pension_date: null,
                pension_salary: null,
            },
            spouse_data: {
                marital_status: null,
                spouse_fio: null,
                spouse_birthdate: null,
                spouse_phone: null,
            },

            employer: { name: "", address: "" },
            snils: null,
            accept: false,
            prod: {},
            dataText: "",
            openData: false,
            openParam: false,
            openHelp: true,
            checkPhoto: false,
            currentCompany: [],
            resultMessage: "",

            isFetching: false,
            isValidated: false
        };
    },
    mounted() {
        this.creditId = this.$route.params.id;
        this.dataText = DATAPROT;
        axios.post("https://vksp.ru:8080/api/credit/set/"+this.creditId)
               
    },
    methods: {
        addTopField() {
            setTimeout(() => {
                if (
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                        navigator.userAgent
                    )
                )
                    this.$refs.employerField.scrollIntoView(true);
            }, 100);
        },
        setCompany(data) { 
            if(data.name) this.employment_data.employer = '';
            setTimeout(() => {
                data.address &&
                    (this.employment_data.employer_address = data.address);
                this.employment_data.employer =
                    data.name ?? this.employment_data.employer;  
            }, 0)   
        },
        async getAddressHandler(query) {

            try {
		if (query && query["name"] !== undefined) {
		query = query.name;
		}
                const url =
                    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
                const token = "3cbba45bf1fd69de807c4f5e04a4e72a341f4b9e";
                const options = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: "Token " + token,
                    },
                    body: JSON.stringify({
                        query,
                    }),
                };

                const company = await fetch(url, options)
                    .then((response) => response.text())
                    .then((result) => JSON.parse(result).suggestions);

                if (company.length) {
                    this.currentCompany = company.map((item) => ({
                        name: item.unrestricted_value,
                        address: item.data?.address?.value,
                    }));
                } else {
                    this.currentCompany = [];
                }
            } catch (e) {
                console.log(e);
            }
        },

        async uploadHandler(data) {
            this.docs[data.key] = data.image[0].imageFile;
            if (
                this.docs["scan1"] == null ||
                this.docs["scan2"] == null ||
                this.docs["photo"] == null
            )
                this.checkPhoto = false;
            else this.checkPhoto = true;
        },
        sendForm() {
		    if (this.spouse_data.marital_status !== 1) {
			this.spouse_data.spouse_phone = null;
			this.spouse_data.spouse_birthdate = null;
			this.spouse_data.spouse_fio = null;
			}
			if (this.main_data.employment_status == 1) {
			this.pension_data.pension_date = null;
			this.pension_data.pension_salary = null;
			} else if (this.main_data.employment_status == 2) {
			this.employment_data.position = "ИП";
			this.pension_data.pension_date = null;
			this.pension_data.pension_salary = null;
			} else if (this.main_data.employment_status == 3) {
			this.employment_data.employer = null;
			this.employment_data.employer_address = null;
			this.employment_data.position = null;
			this.employment_data.employment_start = null;
			this.employment_data.salary = null;
			this.employment_data.employer_phone = null;
			}
            if(this.isFetching) return;
            this.isFetching = true;
            if(!this.checkMainData ||
               !this.checkContacts ||
               !this.checkEmployment ||
               !this.checkSpouse ||
               !this.checkPhoto) {
                    this.$refs.form.validate();
                    this.isValidated = true;
                    this.isFetching = false;
                    return;
            }
            let obj = {
                id: this.creditId,
                type: "app",
                ...this.main_data,
                ...this.contact_data,
                ...this.employment_data,
                ...this.pension_data,
                ...this.contact_data,
                ...this.spouse_data,
                snils: this.snils,
                employment_status: this.employment_statuses.find(
                    (item) => item.id == this.main_data.employment_status
                )?.name,
                education: this.education.find(
                    (item) => item.id == this.main_data.education
                )?.name,
                marital_status: this.marital_statuses.find(
                    (item) => item.id == this.spouse_data.marital_status
                )?.name,
                contact: this.contacts.find(
                    (item) => item.id == this.contact_data.contact
                )?.name,
                pension_date: this.pension_data.pension_date,
                pension_salary: this.pension_data.pension_salary,
            };

            let formdata = new FormData();
            formdata.append("formdata", JSON.stringify(obj));
            for (let key in this.docs) {
                formdata.append(`${key}`, this.docs[key]);
            }
            axios
                .post("https://vksp.ru:8080/api/credit/app/"+this.creditId, formdata)
                .then((res) => {
                    this.isFetching = false;
                    if (res) {
                        this.resultMessage = "";
                        this.$router.push({
                            name: "AppFinal",
                        });
                    } else {
                        this.resultMessage =
                            "Не удалось отправить форму, попробуйте позже";
                    }
                })
                .catch((err) => {
                    this.isFetching = false;
                    this.resultMessage =
                        "Не удалось отправить форму, попробуйте позже";
                    console.log(err);
                });
        },
        setDate(event, field) {
            field = field.split(".");
            event = event.split("-");
            this[field[0]][field[1]] =
                event[2] + "." + event[1] + "." + event[0];
        },
        inputPhone(val, field) {
            setTimeout(() => {
                if (val == 8 || val == 7) {
                    this[field.split(".")[0]][field.split(".")[1]] = "+7 (";
                } else if (val[0] != "+") {
                    this[field.split(".")[0]][field.split(".")[1]] =
                        "+7 (" + val;
                }
            }, 0);
        },
        setSelectValue({ field, value }) {
            this[field.split(".")[0]][field.split(".")[1]] = value;
        },
        removeFocus(val, length) {
            if (val.length == length) {
                document.activeElement.blur();
            }
        },
    },
    computed: {
        sm() {
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
        },
        checkMainData() {
            for (let key in this.main_data)
                if (
                    this.main_data[key] == null ||
                    this.main_data[key] == "" ||
                    this.accept == false ||
                    !this.main_data.phone?.match(
                        /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/
                    )
                )
                    return false;

            return true;
        },
        checkContacts() {
            for (let key in this.contact_data) {
                if (
                    this.contact_data[key] == null ||
                    this.contact_data[key] == ""
                )
                    return false;
            }

            if (
                !this.contact_data.contact_phone.match(
                    /^\+7\s\(9\d{2}\)\s\d{3}-\d{2}-\d{2}$/
                )
            )
                return false;

            return true;
        },
        checkEmployment() {
            if (this.main_data.employment_status == 3) {
                for (let key in this.pension_data) {
                    if (
                        this.pension_data[key] == null ||
                        this.pension_data[key] == ""
                    )
                        return false;
                }

                if (this.pension_data.pension_date.length < 10) return false;

                return true;
            }

            if (
                this.main_data.employment_status == 1 ||
                this.main_data.employment_status == 2
            ) {
                for (let key in this.employment_data) {
                    if (
                        this.employment_data[key] == null ||
                        this.employment_data[key] == ""
                    )
                        return false;
                }

                if (
                    this.employment_data.employment_start.length < 4 ||
                    !this.employment_data.employer_phone?.match(
                        /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/
                    )
                )
                    return false;
            }

            return true;
        },
        checkSpouse() {

            if (this.spouse_data.marital_status == 1) {
                for (let key in this.spouse_data)
                    if (
                        this.spouse_data[key] == null ||
                        this.spouse_data[key] == ""
                    )
                        return false;

                if (
                    !this.spouse_data.spouse_phone.match(
                        /^\+7\s\(9\d{2}\)\s\d{3}-\d{2}-\d{2}$/
                    ) ||
                    this.spouse_data.spouse_birthdate.length < 10
                )
                    return false;
            }

            return true;
        },
    },
};
</script>

<style src="./Credit.scss" lang="scss"></style>
<style>
.error-message {
    color: tomato;
}
.credit__container {
    position: relative;
}
.preloader {
    background-color: #ffffffa1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}
.v-autocomplete__content.v-menu__content {
    max-height: 200px !important;
}
</style>
