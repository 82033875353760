import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import config from '@/config'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    options: {
        customProperties: true,
    },
    themes: {
        dark: config.dark,
        light: config.light,
    }
}
});
