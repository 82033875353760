<template>
    <div class="imagesUpload">
        <v-row>
            <v-col
                v-for="(image, index) in images"
                :key="image.title"
                :style="maxWidth ? `width: ${maxWidth}` : ''"
                :class="classesCol"
                cols="6"
                md="4"
            >
                <div class="modalContainer">
                    <ImagePicker
                        v-model="image.images"
                        :close="close"
                        :activeImageUploads="image.activeImageUploads"
                        @checkDisabled="openGallery($event, index)"
                        @input="UploadHandler($event, image.key)"
                        :webcam="webcam.includes(image.key)"
                        :pb="!!webcam.length && !webcam.includes(image.key)"
                        :height="height"
                        :ratio="{ w: ratio.w, h: ratio.h }"
                        :webcamIcon="true"
                        :label="image.title"
                        :readonly="readonly"
                    >
                        <v-img
                            src="@/assets/placeholder-photo.jpg"
                            class="mb-2 some-photo"
                            :height="Boolean(height) ? height + 5 : height"
                            :aspect-ratio="ratio.w / ratio.h"
                            ref="placeholder"
                            style="border-radius: 4px"
                            :id="'photo-' + image.key"
                            :class="{'error-div': validated && !uploaded[image.key]}"
                        ></v-img>
                    </ImagePicker>
                    <button
                        class="modalContainerBtn"
                        @click="openCameraModal(image)"
                        type="button"
                    ></button>
                </div>
            </v-col>
        </v-row>
        <gallery
            :id="random"
            :images="galleryImg"
            :index="indexGallery"
            @close="indexGallery = null"
        ></gallery>
        <v-dialog v-model="openCamera" max-width="700">
            <v-card class="pa-5 w-100">
                <v-btn icon class="closeModal" @click="openCamera = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div>
                    <p class="text-h5">Сделать фото</p>
                </div>
                <v-row>
                    <camera
                        :cameraType="cameraType"
                        @uploadHandler="UploadHandler"
                        :imgError="imgError"
                    ></camera>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import config from "@/config";
import VueGallery from "@/components/Gallery/Gallery";
import ImagePicker from "@/components/imagePicker/ImagePicker";
import { imageUploadingStates } from "@/components/imagePicker/enums";
import Camera from "./Camera.vue";

export default {
    props: {
        validated: {
            type: Boolean,
            default: () => false,
        },
        classesCol: {
            type: Array,
            default: () => ["photoContainer"],
        },
        classesRow: {
            type: Array,
            default: () => [],
        },
        types: {
            type: Array,
            default: () => [],
        },
        cols: {
            type: [Number, String],
            default: 3,
        },
        maxWidth: {
            type: [Number, String],
            default: null,
        },
        method: {
            type: String,
            default: "user",
        },
        id: {
            type: [Number, String],
            default: null,
        },
        height: {
            type: [Number, String],
            default: null,
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        webcam: {
            type: Array,
            default: () => [],
        },
        uploadImages: {
            type: Array,
            default: () => [],
        },
        close: {
            type: Boolean,
            default: true,
        },
        ratio: {
            type: Object,
            default: () => ({ w: 16, h: 9 }),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        gallery: VueGallery,
        ImagePicker,
        Camera,
    },
    data() {
        return {
            indexGallery: null,
            config,
            images: [],
            cameraType: "",
            openCamera: false,
            imgError: null,
            uploaded: {
                scan1: false,
                scan2: false,
                photo: false,
            }
        };
    },
    mounted() {
        this.images = Object.assign(
            [],
            this.types.map((item) => ({
                ...item,
                state: "NEW",
                images: [],
                activeImageUploads: {
                    progress: 0,
                    state: imageUploadingStates.EMPTY,
                },
            }))
        );
        this.alreadyUpload(this.uploadImages);
    },
    methods: {
        ...mapMutations(["SET_MESSAGES"]),
        openCameraModal({ key }) {
            this.cameraType = key;
            this.openCamera = true;
        },
        async UploadHandler(image, key) {
            this.uploaded[key] = true;
            this.$emit("input", { image, key });
            this.openCamera = false;
            const photo = document.querySelector(
                `#photo-${key} .v-image__image`
            );
            if (photo)
                photo.style.backgroundImage = `url(${image[0].imageURL})`;
        },
        openModal(key) {
            this.$emit("click", { key });
        },
        openGallery(event, index) {
            if (event) {
                this.indexGallery = index;
            }
        },
        alreadyUpload(docs) {
            docs.forEach((doc) => {
                if (this.images.length) {
                    let img = this.images.find(
                        (image) => image.key == doc.type
                    );
                    img.images = [{ imageURL: config.baseURLApi + doc.path }];
                    img.activeImageUploads.state = "COMPLETE";
                }
            });
        },
    },
    watch: {
        images: {
            handler(value) {
                const check = !!value.find(
                    (item) => item.activeImageUploads.state !== "COMPLETE"
                );
                this.$emit("checkUpload", check);
            },
            deep: true,
        },
        uploadImages: {
            handler(value) {
                this.alreadyUpload(value);
            },
        },
    },
    computed: {
        random() {
            return Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, "")
                .substr(0, 5);
        },
        fullImages() {
            return !!this.images.find(
                (item) => item.activeImageUploads.state !== "COMPLETE"
            );
        },
        galleryImg() {
            let array = [];
            this.images.forEach((item) => {
                if (item.images.length) {
                    array.push(item.images[0].imageURL);
                }
            });
            return array;
        },
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                    navigator.userAgent
                )
            )
                return true;

            return false;
        },
    },
};
</script>

<style>
.photoContainer {
    text-align: center;
}

.modalContainer {
    position: relative;
}

.modalContainerBtn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    outline: none;
    display: block;
    width: 100%;
}

.modalContainerBtn:active,
.modalContainerBtn:focus {
    outline: none;
}

.imagesUpload > .row {
    justify-content: center;
}

.error-div {
    border: 1px solid #ff4040;
}
</style>
