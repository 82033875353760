var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"border",staticStyle:{"position":"relative"}},[_c('button',{staticClass:"rotateCamera",on:{"click":_vm.onCameraChange}},[_c('img',{attrs:{"src":require("@/assets/img/exchange.png"),"alt":"Повернуть камеру"}})]),(_vm.cameraType == 'scan1')?_c('img',{staticClass:"photo-frame",class:{
                        'photo-frame--mobile':
                            !_vm.checkIsPhone() && !_vm.checkIsIos(),
                    },attrs:{"src":require("@/assets/img/scan1_frame.png")}}):_vm._e(),(_vm.cameraType == 'scan2')?_c('img',{staticClass:"photo-frame",class:{
                        'photo-frame--mobile':
                            !_vm.checkIsPhone() && !_vm.checkIsIos(),
                    },attrs:{"src":require("@/assets/img/scan2_frame.png")}}):_vm._e(),(_vm.cameraType == 'photo')?_c('img',{staticClass:"photo-frame photo-frame--head",class:{
                        'photo-frame--mobile':
                            !_vm.checkIsPhone() && !_vm.checkIsIos(),
                    },attrs:{"src":require("@/assets/img/photo_frame.png")}}):_vm._e(),(_vm.cameraType == 'photo')?_c('p',{staticClass:"warning-text"},[_c('b',{staticClass:"warning-red"},[_vm._v("ВНИМАНИЕ! ")]),_c('br'),_vm._v("ФОТО ДОЛЖНО БЫТЬ НА ОДНОТОННОМ ФОНЕ, В ОДЕЖДЕ, БЕЗ ГОЛОВНОГО УБОРА, БЕЗ ОЧКОВ ")]):_vm._e(),(_vm.cameraType != 'photo')?_c('p',{staticClass:"warning-text"},[_c('b',{staticClass:"warning-red"},[_vm._v("ВНИМАНИЕ! ")]),_c('br'),_vm._v("ФОТО ДОЛЖНО ЗАХВАТЫВАТЬ ВЕСЬ ПАСПОРТ ")]):_vm._e(),_c('vue-web-cam',{ref:"webcam",staticStyle:{"background-color":"black"},attrs:{"device-id":_vm.deviceId,"width":"100%","resolution":{ height: 600, width: 800 }},on:{"cameras":_vm.onCameras}})],1),_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.imgError))]),_c('div',{staticClass:"row btn-menu"},[(_vm.checkIsPhone())?_c('div',{staticClass:"col btn-item"},[_c('input',{ref:"fileInput",attrs:{"type":"file"},on:{"change":_vm.uploadPhoto}}),_c('v-btn',{staticClass:"btn-photo",attrs:{"color":"primary","x-large":""},on:{"click":_vm.chooseFile}},[_vm._v(" Прикрепить ")])],1):_vm._e(),_c('div',{staticClass:"col btn-item"},[_c('v-btn',{staticClass:"btn-photo",attrs:{"disabled":!_vm.devices.length,"color":"success","x-large":""},on:{"click":_vm.onCapture}},[_vm._v(" Сделать фото ")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }