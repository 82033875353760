<template>
    <div>
        <!-- slot for parent component to activate the file changer -->
        <div @click="launchFilePicker()">
            <slot name="activator" />
        </div>
        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input
            ref="file"
            :name="uploadFieldName"
            multiple
            type="file"
            capture="camera"
            :disabled="disabled"
            style="display: none"
            accept=".jpg, .jpeg, .png"
            @change="onFileChange($event.target)"
        />
    </div>
</template>

<script>
export default {
    name: "ImageInput",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        uploadFieldName: "file",
    }),
    methods: {
        launchFilePicker() {
            this.$refs.file.click();
        },
        onFileChange(file) {
            this.$emit("input", file.files);
            file.value = "";
        },
    },
};
</script>
