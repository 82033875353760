<template>
    <div
        :id="id"
        class="blueimp-gallery blueimp-gallery-controls"
        :class="{ 'blueimp-gallery-carousel': carousel }"
    >
        <div class="slides"></div>
        <h3 class="title"></h3>
        <p class="description"></p>
        <a class="prev" @click="--slideIndex">
            <slot name="prev">‹</slot>
        </a>
        <a class="next" @click="++slideIndex">
            <slot name="next">›</slot>
        </a>
        <a v-if="!carousel" class="close">
            <slot name="close">×</slot>
        </a>
        <ol v-if="!carousel" class="indicator"></ol>
        <a v-if="carousel" class="play-pause"></a>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import "blueimp-gallery/css/blueimp-gallery.min.css";
import "blueimp-gallery/js/blueimp-gallery-fullscreen.js";
import "blueimp-gallery/js/blueimp-gallery-video.js";
import "blueimp-gallery/js/blueimp-gallery-youtube.js";
import blueimp from "blueimp-gallery/js/blueimp-gallery.js";
import config from "../../config";

export default {
    props: {
        images: {
            type: Array,
            default() {
                return [];
            },
        },

        options: {
            type: Object,
            default() {
                return {};
            },
        },

        carousel: {
            type: Boolean,
            default: false,
        },

        index: {
            type: Number,
        },
        id: {
            type: String,
            default: "blueimp-gallery",
        },
    },

    data() {
        return {
            config,
            instance: null,
            slideIndex: 0,
        };
    },

    watch: {
        index(value) {
            if (this.carousel) {
                return;
            }

            if (value !== null) {
                this.open(value);
            } else {
                if (this.instance) {
                    this.instance.close();
                }

                this.$emit("close");
            }
        },
    },

    mounted() {
        if (this.carousel) {
            this.open();
        }
    },

    destroyed() {
        if (this.instance !== null) {
            this.instance.destroyEventListeners();
            this.instance.close();
            this.instance = null;
        }
    },

    methods: {
        ...mapActions("documents", ["rotateRequest"]),
        open(index = 0) {
            const instance =
                typeof blueimp.Gallery !== "undefined"
                    ? blueimp.Gallery
                    : blueimp;

            const options = Object.assign(
                {
                    toggleControlsOnReturn: false,
                    toggleControlsOnSlideClick: false,
                    closeOnSlideClick: false,
                    carousel: this.carousel,
                    container: `#${this.id}`,
                    index,
                    onopen: () => this.$emit("onopen"),
                    onopened: () => this.$emit("onopened"),
                    onslide: this.onSlideCustom,
                    onslideend: (index, slide) =>
                        this.$emit("onslideend", { index, slide }),
                    onslidecomplete: (index, slide) =>
                        this.$emit("onslidecomplete", { index, slide }),
                    onclose: () => this.$emit("close"),
                    onclosed: () => this.$emit("onclosed"),
                },
                this.options
            );

            if (this.carousel) {
                options.container = this.$el;
            }

            this.instance = instance(this.images, options);
            this.slideIndex = this.$props.index;
        },
        onSlideCustom(index, slide) {
            this.$emit("onslide", { index, slide });

            const image = this.images[index];
            if (image !== undefined) {
                const text = image.description;
                const node = this.instance.container.find(".description");
                if (text) {
                    node.empty();
                    node[0].appendChild(document.createTextNode(text));
                }
            }
        },

        async rotate(x) {
            const imageSrc = this.$props.images[this.slideIndex];
            const img = document.querySelector(`[src='${imageSrc}']`);

            img.removeAttribute("src");
            const documents = await this.rotateRequest({
                path: this.$props.images[this.slideIndex].split("?")[0],
                rotate: x,
            });
            if (documents) {
                const newImg =
                    this.$props.images[this.slideIndex] + "?" + Math.random();
                this.$props.images[this.slideIndex] = newImg;
                img.setAttribute("src", newImg);
                const images = document.querySelectorAll(".v-image__image");
                for (let i of images) {
                    let newImage =
                        i.style.backgroundImage.split('"')[1] +
                        "?" +
                        Math.random();
                    i.style.backgroundImage = `url(${newImage})`;
                }
            }
        },
    },
};
</script>

<style>
.blueimp-gallery > .description {
    position: absolute;
    top: 30px;
    left: 15px;
    color: #fff;
    display: none;
}
.blueimp-gallery-controls > .description {
    display: block;
}
.editPanel {
    position: fixed;
    top: 15px;
    left: 15px;
}
.editPanel .editPanel-btn.editPanel-btn {
    background: none;
    margin-right: 10px;
    padding: 10px;
    min-width: auto;
    color: #fff;
}
</style>
