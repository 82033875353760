<template>
    <section id="lp-blocks1" class="lp-blocks lp-blocks1 lp-blocks-rows-4">
        <header class="lp-section-header">
            <h2 class="lp-section-title">СОТРУДНИЧЕСТВО</h2>
        </header>
        <div class="lp-blocks-inside">
            <div class="page">
                <p class="txt">
                    С компанией «ВКС» Ваши клиенты (покупатели) могут приобрести
                    товар (услугу) в кредит или в рассрочку у Вас на торговой
                    точке!<br />
					- Личный кабинет для формирования кредитных заявок.<br>
                    - Дистанционное оформление кредита или рассрочки по
                    всей России.
                </p>

                <h5>Оформление кредита/рассрочки:</h5>
                <li class="litxt">
                     - В нескольких банках одновременно: Альфа Банк, ОТП Банк, МТС
                    Банк, Тинькофф Банк, Кредит Европа Банк, Почта Банк, Банк
                    Русский Стандарт, СовКомБанк.
                </li>
                <li class="litxt">
                    - Заполнение анкеты и ожидание ответа 15-20 минут.
                </li>
                <li class="litxt"> - Без страховок и дополнительных продуктов.</li>
                <li class="litxt">
                     - Низкие процентные ставки по банковским продуктам.
                </li>

                <h5>Требования к заемщику:</h5>
                <li class="litxt"> - Без первоначального взноса.</li>
                <li class="litxt"> - По одному документу (только паспорт).</li>
                <li class="litxt"> - Сроки от 4 до 48 месяцев.</li>
                <li class="litxt"> - На сумму от 5 000 до 1 500 000 рублей.</li>

                <h5>В случае одобрения:</h5>
                <li class="litxt">
                     - Клиент подписывает необходимый пакет документов (кредитный
                    договор и т.д.).
                </li>
                <li class="litxt">
                     - Первоначальный взнос (если есть) заемщик вносит в кассу
                    партнера.
                </li>
                <li class="litxt">
                     - Компания «ВКС» переводит на Ваш расчетный счет денежные
                    средства за товар (услугу) в течении 1-3 рабочих дней.
                </li>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style></style>
