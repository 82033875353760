<template>
    <v-app>
        <v-main>
            <component :is="layout">
                <router-view />
            </component>
        </v-main>
    </v-app>
</template>

<script>
import MainLayout from "./layouts/MainLayout.vue";
import MainWithBannerLayout from "./layouts/MainWithBannerLayout.vue";
import EmptyLayout from "./layouts/EmptyLayout.vue";

export default {
    name: "App",

    data: () => ({
        //
    }),
    computed: {
        layout() {
            if (this.$route.meta.layout == "withBanner")
                return "MainWithBanner-layout";
            return (this.$route.meta.layout || "empty") + "-layout";
        },
    },
    components: {
        MainLayout,
        MainWithBannerLayout,
        EmptyLayout,
    },
};
</script>
<style src="./styles/app.scss" lang="scss"></style>

<!-- Подключение шрифтов -->
<style src="./assets/css/css.css"></style>
<style src="./assets/css/style.css"></style>
