<template>
    <div class="credit lp-blocks">
        <div class="credit__container">
            <p class="text-h6 pt-5 mb-1" v-if="!sm">
                Короткая форма на получение кредита
            </p>
            <v-divider></v-divider>
            <div class="credit__calc mt-3">
                <v-row class="justify-space-between">
                    <v-col
                        cols="12"
                        sm="12"
                        md="9"
                        lg="9"
                        class="credit__content"
                    >
                        <transition name="fade" mode="out-in">
                            <div class="appStep">
                                <v-form ref="form" lazy-validation>
                                    <p
                                        class="
                                            text-h5
                                            font-weight-bold
                                            greyBold--text
                                            mb-5
                                        "
                                    >
                                        Что вы планируете купить
                                    </p>
                                    <v-row class="align-center">
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="6"
                                            class="d-flex align-center"
                                            :class="{ 'pb-0 pt-0': sm }"
                                        >
                                            <v-text-field
                                                outlined
                                                class="font-weight-medium"
                                                label="Название товара"
                                                placeholder="Название товара"
                                                :rules="nameRules"
                                                v-model="form.product"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="6"
                                            class="py-1"
                                        >
                                            <v-text-field
                                                outlined
                                                class="font-weight-medium"
                                                label="Фамилия Имя Отчество"
                                                placeholder="Фамилия Имя Отчество"
                                                :rules="fioRules"
                                                v-model="form.fio"
                                                hint="Укажите точно как в паспорте"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="6"
                                            class="py-1"
                                        >
                                            <v-text-field
                                                @input="
                                                    (val) =>
                                                        inputPhone(
                                                            val,
                                                            'form.phone'
                                                        )
                                                "
                                                outlined
                                                class="font-weight-medium"
                                                label="Мобильный телефон"
                                                placeholder="Мобильный телефон"
                                                :rules="phoneRules"
                                                inputmode="tel"
                                                v-model="form.phone"
                                                v-mask="'+7 (###) ###-##-##'"
                                                hint="Нужен кредитному специалисту для связи с вами"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-checkbox
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Необходимо принять условия',
                                        ]"
                                        v-model="accept"
                                        class="
                                            font-weight-medium
                                            mt-4
                                            text-lh-lg
                                        "
                                    >
                                        <template v-slot:label>
                                            <div class="text-subtitle-2">
                                                <span>Я соглашаюсь </span>
                                                <span
                                                    class="success--text"
                                                    @click="
                                                        openData = true;
                                                        accept = !accept;
                                                    "
                                                    >с условиями обработки
                                                    персональных данных</span
                                                >
                                                <span> и </span>
                                                <span
                                                    class="success--text"
                                                    @click="
                                                        openParam = true;
                                                        accept = !accept;
                                                    "
                                                    >параметрами
                                                    потребительского
                                                    кредитования.</span
                                                >
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <div
                                        class="d-flex justify-center"
                                        :class="
                                            sm
                                                ? 'flex-column mt-2'
                                                : 'align-center mt-5'
                                        "
                                    >
                                        <v-btn
                                            color="primary"
                                            :class="{ 'mt-5': sm }"
                                            :disabled="!valid"
                                            x-large
                                            @click="sendForm"
                                        >
                                            Отправить
                                        </v-btn>
                                    </div>
                                </v-form>
                            </div>
                        </transition>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        class="d-flex credit__steps"
                    >
                        <v-divider vertical v-if="!sm"></v-divider>
                        <div
                            class="appSteps grey--text font-weight-bold w-100"
                            v-if="!openHelp"
                            :class="sm ? 'pa-0' : ' pa-5'"
                        >
                            <v-progress-linear :value="100"></v-progress-linear>
                            <ul class="mt-5" v-if="!sm">
                                <li
                                    v-for="item in steps"
                                    :key="item.step"
                                    class="mt-2 greyBold--text"
                                >
                                    <v-icon class="chevron-li">
                                        mdi-chevron-right
                                    </v-icon>
                                    {{ item.name }}
                                </li>
                            </ul>
                        </div>
                        <v-card
                            v-else
                            class="greyBold--text helpCart"
                            :class="sm ? 'pa-3' : 'pa-5'"
                        >
                            <div
                                class="
                                    d-flex
                                    justify-space-between
                                    align-center
                                    mb-3
                                "
                            >
                                <p class="text-h6 font-weight-bold mb-0">
                                    Как это работает
                                </p>
                                <v-btn icon @click="openHelp = false">
                                    <v-icon size="30">mdi-close</v-icon>
                                </v-btn>
                            </div>
                            <ul class="m-0 pa-0">
                                <li class="d-flex text-subtitle-1 mb-3 ln-15">
                                    <span
                                        class="
                                            success--text
                                            font-weight-bold
                                            mr-2
                                        "
                                        >1.</span
                                    >Заявка в 10 банков
                                </li>
                                <li class="d-flex text-subtitle-1 mb-3 ln-15">
                                    <span
                                        class="
                                            success--text
                                            font-weight-bold
                                            mr-2
                                        "
                                        >2.</span
                                    >Хорошая одобряемость
                                </li>
                                <li class="d-flex text-subtitle-1 mb-3 ln-15">
                                    <span
                                        class="
                                            success--text
                                            font-weight-bold
                                            mr-2
                                        "
                                        >3.</span
                                    >По одному паспорту
                                </li>
                                <li class="d-flex text-subtitle-1 mb-3 ln-15">
                                    <span
                                        class="
                                            success--text
                                            font-weight-bold
                                            mr-2
                                        "
                                        >4.</span
                                    >Вы гражданин РФ
                                </li>
                                <li class="d-flex text-subtitle-1 mb-3 ln-15">
                                    <span
                                        class="
                                            success--text
                                            font-weight-bold
                                            mr-2
                                        "
                                        >5.</span
                                    >Вы официально трудоустроены или пенсионер
                                </li>
                            </ul>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <v-dialog v-model="openData" max-width="900">
                <v-card :class="sm ? 'pa-5' : 'pa-15'">
                    <v-btn icon class="closeModal" @click="openData = false">
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
                    <div
                        class="d-flex align-center justify-center"
                        :class="{ 'flex-wrap': sm }"
                    >
                        <img
                            src="../assets/img/logo.png"
                            class="custom-logo mr-5"
                            alt=""
                        />
                        <p class="text-h5" :class="{ 'mt-5': sm }">
                            Я ПОДТВЕРЖДАЮ СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ
                            ДАННЫХ
                        </p>
                    </div>
                    <p class="text-subtitle-2 mt-5">
                        {{ dataText }}
                    </p>
                </v-card>
            </v-dialog>
            <v-dialog v-model="openParam" max-width="700">
                <v-card :class="sm ? 'pa-5' : 'pa-10'">
                    <v-btn icon class="closeModal" @click="openParam = false">
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
                    <div class="d-flex align-center flex-wrap">
                        <p class="text-h5">
                            Параметры потребительского кредитования
                        </p>
                    </div>
                    <v-row class="text-subtitle-2 mt-5 justify-space-between">
                        <v-col cols="6">
                            <p><b class="mb-5">Кому подходит</b></p>
                            <ul class="pa-0">
                                <li class="mt-2">— гражданам России</li>
                                <li class="mt-2">— с действующим паспортом</li>
                                <li class="mt-2">— постоянной пропиской</li>
                                <li class="mt-2">— постоянным доходом</li>
                            </ul>
                        </v-col>
                        <v-col cols="6">
                            <p><b class="mb-5">Что обязательно</b></p>
                            <ul class="pa-0">
                                <li class="mt-2">
                                    — наличие номера мобильного телефона
                                </li>
                                <li class="mt-2">
                                    — работающие граждане указывают рабочий
                                    телефон
                                </li>
                                <li class="mt-2">
                                    — неработающие пенсионеры дополнительно
                                    указывают домашний или другой контактный
                                    номер телефона
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { DATAPROT } from "../dataJson/dataProt";
import { words } from "../dataJson/words";
import axios from "axios";

function check(v) {
    var t;
    for (var i = 0; i < words.length; i++) {
        if (v.toLowerCase().includes(words[i].toLowerCase())) {
            t = 1;
        }
    }
    if (t == 1) {
        return true;
    } else {
        return false;
    }
}
export default {
    data() {
        return {
            dataText: "",
            openData: false,
            openParam: false,
            openHelp: true,
            type: "product",
			fioRegex: /^[а-яА-ЯЁё\s]+$/,
            steps: [
                {
                    id: 0,
                    name: "Товары",
                    step: 1,
                },
            ],
            form: {
                type: "credit",
                product: "",
                fio: "",
                phone: "",
            },
            accept: false,
            nameRules: [
                (v) => !!v || "Укажите название товара",
                (v) => v.length >= 3 || "Минимум 3 символа",
				(v) => v.length < 30 || "Максимум 20 символов",
                (v) =>
                    !check(v) ||
                    "Данный товар не подходит по условиям кредитования",
            ],
            fioRules: [
			(v) => !!v || "Укажите ФИО",
			(v) => this.fioRegex.test(v) || "Только русские символы",
			],
            phoneRules: [(v) => !!v || "Укажите телефон"],
        };
    },
    mounted() {
        this.dataText = DATAPROT;
    },
    methods: {
        sendForm() {
            let partner = this.$route.params.id
                ? {
                      partner_id: this.$route.params.id,
                  }
                : {};
            let obj = {
                type: "credit",
                ...this.form,
                ...partner,
            };
            //let formdata = new FormData();
            //formdata.append("formdata", JSON.stringify(obj));

            axios
                .post("https://vksp.ru:8080/api/credit/create", obj)
                .then(() => {
                    this.$router.push({
                        name: "CreditFinal",
                        params: {
                            name: this.form.fio,
                            phone: this.form.phone,
                        },
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        inputPhone(val, field) {
            setTimeout(() => {
                if (val == 8 || val == 7) {
                    this[field.split(".")[0]][field.split(".")[1]] = "+7 (";
                } else if (val[0] != "+") {
                    this[field.split(".")[0]][field.split(".")[1]] =
                        "+7 (" + val;
                }
            }, 0);
        },
    },
    computed: {
        sm() {
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
        },
        valid() {

			return (
				this.accept &&
				this.form.product &&
				this.form.product.length <= 35 &&
				this.form.product.length >= 3 &&
				this.form.fio &&
				this.fioRegex.test(this.form.fio) &&
				this.form.phone &&
				this.form.phone.length == 18
			);
        },
    },
};
</script>

<style src="./Credit.scss" lang="scss"></style>
