<template>
  <v-app>
    <v-container fluid class="error-page">
      <v-row class="d-flex justify-center">
        <v-col cols="8" class="mt-10 text-center">
          <div v-if="safeLink" class="mt-5 card">
            <span class="text-h3">ВКС</span>
            <p class="mt-5 text-h6">
              Вы будете перенаправлены по внешней ссылке через {{ counter }} секунд
            </p>
			<a :href="safeLink" class="text-h4 green--text" target="_blank" rel="noopener noreferrer">{{this.safeLink}}</a>
			
            <p class="mt-10" v-if="safeLink">
              Если перенаправление не происходит, <a class="green--text font-weight-bold" :href="safeLink" target="_blank" rel="noopener noreferrer">нажмите здесь</a>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'Go',
  data() {
    return {
      counter: 5,
      safeLink: null,
      interval: null,
    };
  },
created() {
  // Получение параметра link из строки запроса
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let link = urlParams.get('link');

  if (link) {
    try {
      // Проверка наличия протокола "http://" или "https://"
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        link = 'http://' + link;
      }
      // Убедитесь в безопасности URL.
      this.safeLink = new URL(link).href;
      // Инициализация таймера
      this.startTimer();
    } catch (error) {
      console.error('Invalid link provided:', error);
    }
  } else {
    console.error('No link provided');
  }
},
  methods: {
    startTimer() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          this.redirect();
        }
      }, 1000);
    },
    redirect() {
      clearInterval(this.interval);
      if (this.safeLink) {
        window.location.href = this.safeLink;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
