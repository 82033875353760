<template>
    <div class="imagePicker d-flex flex-column h-100">
        <p
            v-if="label.length"
            class="text-subtitle-2 photo-label black--text mb-2 mt-auto"
        >
            {{ label }}
        </p>
        <div
            @click="getDisabledStatus"
            style="position: relative"
            :style="pb ? 'padding-bottom: 52px;' : ''"
        >
            <image-input disabled="disabled" @input="onFileChange($event)">
                <div slot="activator">
                    <div v-if="!value.length">
                        <slot />
                    </div>
                    <div v-else wrap>
                        <v-flex v-for="avatar in value" :key="avatar.key">
                            <v-hover v-slot="{ hover }">
                                <v-card>
                                    <v-img
                                        :class="{ 'img-hover': hover }"
                                        :height="height"
                                        :src="avatar.imageURL"
                                        :aspect-ratio="ratio.w / ratio.h"
                                    />
                                    <v-progress-linear
                                        v-if="avatar.uploaded"
                                        :value="100"
                                        color="success"
                                    />

                                    <template
                                        v-else-if="
                                            activeImageUploads.state != 'EMPTY'
                                        "
                                    >
                                        <v-progress-linear
                                            v-if="
                                                activeImageUploads.state ===
                                                imageUploadingStates.NEW
                                            "
                                            :indeterminate="true"
                                            height="8"
                                        />
                                        <v-progress-linear
                                            v-else-if="
                                                activeImageUploads.state ===
                                                imageUploadingStates.UPLOADING
                                            "
                                            :value="activeImageUploads.progress"
                                            height="8"
                                        />
                                        <v-progress-linear
                                            v-else-if="
                                                activeImageUploads.state ===
                                                imageUploadingStates.ERROR
                                            "
                                            :value="activeImageUploads.progress"
                                            color="error"
                                            height="8"
                                        />
                                        <v-progress-linear
                                            v-else-if="
                                                activeImageUploads.state ===
                                                imageUploadingStates.COMPLETE
                                            "
                                            :value="activeImageUploads.progress"
                                            color="success"
                                            height="8"
                                        />
                                    </template>
                                </v-card>
                            </v-hover>
                        </v-flex>
                    </div>
                </div>
            </image-input>
        </div>
        <v-dialog v-model="camera" max-width="800">
            <v-card class="pa-5">
                <MulticorderUI v-if="camera" @photo="onFileChange($event)" />
                <v-btn color="primary" outlined @click="camera = false"
                    >Назад</v-btn
                >
            </v-card>
        </v-dialog>
        <template v-if="webcam">
            <v-btn
                icon
                x-large
                v-if="webcamIcon"
                @click="camera = true"
                class="mx-auto"
            >
                <v-icon large> mdi-camera </v-icon>
            </v-btn>
            <v-btn
                v-else
                color="primary"
                outlined
                class="w-100 mt-2"
                @click="camera = true"
                >Фото с веб-камеры</v-btn
            >
        </template>
    </div>
</template>

<script>
import ImageInput from "./image-input";
import { imageUploadingStates } from "./enums";
import MulticorderUI from "./MulticorderUI";
export default {
    name: "ImagePicker",
    components: {
        ImageInput,
        MulticorderUI,
    },
    props: {
        value: {
            type: Array,
            default: null,
        },
        close: {
            type: Boolean,
            default: true,
        },
        max: {
            type: Number,
            default: null,
        },
        maxSize: {
            type: Number,
            default: 8192 * 1024, // 8192 KB
        },
        activeImageUploads: {
            type: Object,
            default: () => ({}),
        },
        exceedMaxImagesError: {
            type: String,
            default:
                "The maximum number of images was exceeded by your selection.",
        },
        invalidFileTypeError: {
            type: String,
            default: "Неправильный тип файла. Выберите png или jpg файл",
        },
        fileSizeError: {
            type: String,
            default:
                "One or more of the files you attempted to upload is larger than the single-file size limit.",
        },
        maxImagesUnit: {
            type: String,
            default: "images",
        },
        clearedImagesMessage: {
            type: String,
            default: "Cleared images",
        },
        clearImagesLabel: {
            type: String,
            default: "clear",
        },
        validImageTypes: {
            type: Array,
            default: () => ["png", "jpg", "jpeg"],
        },
        webcam: {
            type: Boolean,
            default: false,
        },
        height: {
            type: [Number, String],
            default: null,
        },
        label: {
            type: String,
            default: "",
        },
        webcamIcon: {
            type: Boolean,
            default: false,
        },
        pb: {
            type: Boolean,
            default: false,
        },
        ratio: {
            type: Object,
            default: () => ({ w: 16, h: 9 }),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            snackbar: false,
            snackMessage: "",
            snackColor: "info",
            imageUploadingStates,
            errors: [],
            camera: false,
            disabled: false,
        };
    },
    computed: {
        disableAddButton() {
            return !!this.max && !!this.value && this.value.length >= this.max;
        },
        numSelected() {
            return this.value ? this.value.length : 0;
        },
        currentError() {
            return this.errors.length > 0 ? this.errors[0] : null;
        },
    },
    mounted() {
        this.setDisabled(this.activeImageUploads);
        if (this.readonly) this.disabled = true;
    },
    watch: {
        currentError(currentError) {
            if (currentError) {
                this.onErrorMessage(currentError);
            }
        },
        activeImageUploads: {
            handler(value) {
                this.setDisabled(value);
            },
            deep: true,
        },
    },
    methods: {
        setDisabled(image) {
            if (image.state == "COMPLETE") {
                this.disabled = true;
            }
        },
        onFileChange(fileList) {
            this.camera = false;
            const fileArray = [];
            // Convert from fileList to array
            for (let i = 0; i < fileList.length; i++) {
                fileArray.push(fileList[i]);
            }
            const newImages = fileArray.map((imageFile) => ({
                imageFile,
                imageURL: URL.createObjectURL(imageFile),
                name: imageFile.name,
            }));
            this.checkNewImageArray(newImages.length ? newImages : this.value);
        },
        clearImages() {
            this.$emit("input", []);
            if (!this.readonly) this.disabled = false;
        },
        getDisabledStatus() {
            this.$emit("checkDisabled", this.disabled);
        },
        // Pass through to parent
        onErrorMessage(errorMessage) {
            this.snackMessage = errorMessage;
            this.snackColor = "error";
            this.snackbar = true;
        },
        checkNewImageArray(images) {
            this.errors = this.checkForErrors(images);
            if (this.errors.length === 0) {
                this.$emit("input", images);
            }
        },
        checkForErrors(images) {
            const totalFiles = images.length;
            const matchString = `image/${this.validImageTypes.join("|")}`;
            const errors = images.reduce((errors, { imageFile }) => {
                if (this.max && totalFiles > this.max) {
                    // check whether max number of images will be exceeded
                    errors.add(this.exceedMaxImagesError);
                }
                if (!imageFile.type.match(matchString)) {
                    // check whether the upload is an image
                    errors.add(this.invalidFileTypeError);
                }
                if (imageFile.size > this.maxSize) {
                    // check whether the size is greater than the size limit
                    errors.add(this.fileSizeError);
                }

                return errors;
            }, new Set());

            return Array.from(errors);
        },
    },
};
</script>
<style lang="scss">
.imagePicker {
    cursor: pointer;
    .btn-close {
        right: 5px !important;
        top: 5px !important;
        z-index: 99;
    }
    .img-hover {
        background-color: rgba(255, 255, 255, 0.3) !important;
        opacity: 0.7;
        transition: opacity 0.3s;
    }
}

@media screen and (max-width: 420px) {
    .photo-label {
        max-width: 120px;
        margin: auto;
    }
}
</style>
