<template>
    <div>
        <section class="lp-slider">
            <div class="lp-staticslider">
                <img
                    class="lp-staticslider-image"
                    alt="Ваш кредитный специалист"
                    src="../assets/img/broker_Z-3.jpg"
                />
                <div class="staticslider-caption">
                    <h2 class="staticslider-caption-title">
                        Ваш кредитный специалист
                    </h2>
                    <span class="staticslider-sep"></span>
                    <div class="staticslider-caption-text">
                        Кредит или Рассрочка для Ваших Клиентов
                    </div>
                    <div class="staticslider-caption-buttons"></div>
                </div>
            </div>
            <!-- .lp-staticslider -->
        </section>
        <section
            id="lp-blocks1"
            class="lp-blocks lp-blocks1 lp-blocks-rows-4 about-sec"
        >
            <div id="container" class="fluida-landing-page one-column">
                <header class="lp-section-header">
                    <h2 class="lp-section-title">ПОЧЕМУ МЫ</h2>
                </header>
                <div class="lp-blocks-inside">
                    <div class="container">
                        <v-row>
                            <v-col md="4" sm="12" cols="12">
                                <div class="service-area">
                                    <div class="media">
                                        <div class="service-icon">
                                             <v-icon
											 color="primary"
											 size="45"
											 >mdi-finance</v-icon>
                                        </div>
                                        <div class="media-body">
                                            <h3>Профит</h3>
                                            <p>
                                                Сотрудничество с компанией «ВКС»
                                                для Вас абсолютно бесплатно! Мы
                                                поможем Вам увеличить средний
                                                чек, объем продаж и привлечем
                                                новых дополнительных клиентов.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col md="4" sm="12" cols="12">
                                <div class="service-area">
                                    <div class="media">
                                        <div class="service-icon">
                                             <v-icon
											 color="primary"
											 size="45"
											 >mdi-bank</v-icon>
                                        </div>
                                        <div class="media-body">
                                            <h3>Банки</h3>
                                            <p>
                                                Отправление заявки на кредит или
                                                рассрочку одновременно в 8
                                                банков: ОТП Банк, МТС Банк,
                                                Тинькофф, Альфа банк, Кредит
                                                Европа Банк, Почта Банк,
                                                СовКомБанк, Банк Русский
                                                Стандарт.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col md="4" sm="12" cols="12">
                                <div class="service-area">
                                    <div class="media">
                                        <div class="service-icon">
                                             <v-icon
											 color="primary"
											 size="45"
											 >mdi-network-pos</v-icon>
                                        </div>
                                        <div class="media-body">
                                            <h3>Платформа</h3>
                                            <p>
                                                Личный кабинет для партнеров - создание и отслеживание кредитных заявок в режиме онлайн. 
												Решение банков за 15 минут. Высокая вероятность одобрения.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col md="4" sm="12" cols="12">
                                <div class="service-area">
                                    <div class="media">
                                        <div class="service-icon">
                                             <v-icon
											 color="primary"
											 size="45"
											 >mdi-file-document-check</v-icon>
                                        </div>
                                        <div class="media-body">
                                            <h3>Документы</h3>
                                            <p>
                                                Банковский документооборот
                                                компания «ВКС» берет на себя.
                                                Своевременное перечисление
                                                денежных средств на Ваш
                                                расчётный счёт.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col md="4" sm="12" cols="12">
                                <div class="service-area">
                                    <div class="media">
                                        <div class="service-icon">
                                             <v-icon
											 color="primary"
											 size="45"
											 >mdi-calculator</v-icon>
                                        </div>
                                        <div class="media-body">
                                            <h3>Кредит</h3>
                                            <p>
                                                Для Ваших покупателей с
                                                компанией «ВКС» будут предложены
                                                самые низкие процентные ставки
                                                без дополнительных платных услуг
                                                в виде страховок.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col md="4" sm="12" cols="12">
                                <div class="service-area">
                                    <div class="media">
                                        <div class="service-icon">
                                             <v-icon
											 color="primary"
											 size="45"
											 >mdi-check-decagram</v-icon>
                                        </div>
                                        <div class="media-body">
                                            <h3>Гарантии</h3>
                                            <p>
                                                Компания «ВКС» гарантирует
                                                качественный, оперативный сервис
                                                и лояльное отношение к Вашим
                                                клиентам.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
