<template>
    <div class="credit">
        <div class="credit__container">
            <div class="greyBold--text credit__wrap">
                <p :class="sm ? 'text-h5' : 'text-h4'">
                    <b>{{ name }}</b
                    >, ваш запрос отправлен.
                </p>
                <p class="text-subtitle-1 mt-5 credit__textwrap">
                    В ближайшее время по номеру <b>{{ number }}</b> позвонит
                    кредитный специалист, чтобы помочь заполнить заявку на
                    кредитный продукт в полном объёме.
                </p>
                <router-link to="/">
                    <v-btn color="primary" x-large width="300" class="mt-10">
                        Вернуться на сайт
                    </v-btn>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: "",
            number: "",
        };
    },
    mounted() {
        this.name = this.$route.params.name;
        this.number = this.$route.params.phone;
    },
    computed: {
        sm() {
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
        },
    },
};
</script>

<style src="./Credit.scss" lang="scss"></style>
