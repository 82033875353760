<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="border" style="position: relative">
                    <button @click="onCameraChange" class="rotateCamera">
                        <img
                            src="@/assets/img/exchange.png"
                            alt="Повернуть камеру"
                        />
                    </button>
                    <img
                        v-if="cameraType == 'scan1'"
                        class="photo-frame"
                        src="@/assets/img/scan1_frame.png"
                        :class="{
                            'photo-frame--mobile':
                                !checkIsPhone() && !checkIsIos(),
                        }"
                    />
                    <img
                        v-if="cameraType == 'scan2'"
                        class="photo-frame"
                        src="@/assets/img/scan2_frame.png"
                        :class="{
                            'photo-frame--mobile':
                                !checkIsPhone() && !checkIsIos(),
                        }"
                    />
                    <img
                        v-if="cameraType == 'photo'"
                        class="photo-frame photo-frame--head"
                        src="@/assets/img/photo_frame.png"
                        :class="{
                            'photo-frame--mobile':
                                !checkIsPhone() && !checkIsIos(),
                        }"
                    />
                    <p class="warning-text" v-if="cameraType == 'photo'">
                        <b class="warning-red">ВНИМАНИЕ! </b><br />ФОТО ДОЛЖНО
                        БЫТЬ НА ОДНОТОННОМ ФОНЕ, В ОДЕЖДЕ, БЕЗ ГОЛОВНОГО УБОРА,
                        БЕЗ ОЧКОВ
                    </p>
                    <p class="warning-text" v-if="cameraType != 'photo'">
                        <b class="warning-red">ВНИМАНИЕ! </b><br />ФОТО ДОЛЖНО
                        ЗАХВАТЫВАТЬ ВЕСЬ ПАСПОРТ
                    </p>
                    <vue-web-cam
                        style="background-color: black"
                        ref="webcam"
                        :device-id="deviceId"
                        width="100%"
                        @cameras="onCameras"
                        :resolution="{ height: 600, width: 800 }"
                    />
                </div>

                <p class="text-danger">{{ imgError }}</p>

                <div class="row btn-menu">
                    <div class="col btn-item" v-if="checkIsPhone()">
                        <input
                            type="file"
                            ref="fileInput"
                            @change="uploadPhoto"
                        />
                        <v-btn
                            color="primary"
                            x-large
                            class="btn-photo"
                            @click="chooseFile"
                        >
                            Прикрепить
                        </v-btn>
                    </div>
                    <div class="col btn-item">
                        <v-btn
                            :disabled="!devices.length"
                            color="success"
                            x-large
                            class="btn-photo"
                            @click="onCapture"
                        >
                            Сделать фото
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WebCam } from "vue-web-cam";

export default {
    props: {
        cameraType: {
            type: String,
            default: "",
        },
        imgError: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            img: null,
            camera: null,
            deviceId: null,
            devices: [],
        };
    },
    components: {
        "vue-web-cam": WebCam,
    },
    mounted() {},
    computed: {
        device: function () {
            return this.devices.find((n) => n.deviceId === this.deviceId);
        },
    },
    watch: {
        camera: function () {
            this.deviceId = this.devices[this.devices.length - 1]?.deviceId;
        },
        devices: function () {
            const [first] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
    },
    methods: {
        uploadPhoto(e) {
            const reader = new FileReader();
            this.img = e.target.files[0];

            if (this.img.type.split("/")[0] != "image")
                return (this.imgError = "Файл должен быть изображением");
            if (this.img.size > 1024 * 1024 * 9)
                return (this.imgError = "Размер фото не должен превышать 9Мб");

            reader.addEventListener("load", () => {
                this.img = reader.result;

                const blob = this.b64toBlob(this.img);

                this.$emit(
                    "uploadHandler",
                    [
                        {
                            imageFile: this.blobToFile(
                                blob,
                                this.cameraType + ".jpeg"
                            ),
                            imageURL: URL.createObjectURL(blob),
                            name: this.cameraType,
                        },
                    ],
                    this.cameraType
                );
            });

            reader.readAsDataURL(this.img);
        },
        chooseFile() {
            this.$refs.fileInput.click();
        },
        onCameraChange() {
            if (
                this.deviceId == this.devices[this.devices.length - 1].deviceId
            ) {
                this.deviceId = this.devices[0].deviceId;
                this.camera = this.devices[0].deviceId;
            } else {
                this.deviceId = this.devices[this.devices.length - 1].deviceId;
                this.camera = this.devices[this.devices.length - 1].deviceId;
            }
        },
        b64toBlob(dataURI) {
            var byteString = atob(dataURI.split(",")[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);

            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: "image/jpeg" });
        },
        blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        },
        onCapture() {
            this.img = this.$refs.webcam.capture();
            console.log(this.$refs.webcam.capture());
            const blob = this.b64toBlob(this.img);
            this.$emit(
                "uploadHandler",
                [
                    {
                        imageFile: this.blobToFile(
                            blob,
                            this.cameraType + ".jpeg"
                        ),
                        imageURL: URL.createObjectURL(blob),
                        name: this.cameraType,
                    },
                ],
                this.cameraType
            );
            this.img = null;
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        checkIsPhone() {
            return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                navigator.userAgent
            );
        },
        checkIsIos() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        },
    },
};
</script>

<style>
.photo-frame {
    position: absolute;
    height: 85%;
    width: 85%;
    top: 7%;
    left: 0;
    right: 0;
    object-fit: contain;
    margin: auto;
}

.photo-frame--head {
    height: 80%;
    top: 7.5%;
}

.photo-frame--mobile {
    top: 5%;
    height: 90%;
    width: 90%;
}

.v-select__selections input {
    display: none;
}

.v-text-field__details {
    display: none;
}

.btn-photo {
    display: block;
    margin: auto;
    max-width: 200px;
    width: 100%;
}

.warning-text {
    position: absolute;
    color: white;
    padding: 0 5px;
    font-size: 9px;
    bottom: -10px;
}

.warning-red {
    color: red;
}

.rotateCamera {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    padding: 5px;
    z-index: 20;
}

video {
    height: 400px;
    width: 100%;
    padding: 40px 0;
}

.btn-menu {
    margin: 0px -12px -10px;
}

.btn-item input {
    display: none;
}

.text-danger {
    color: #dc3545;
    text-align: center;
    font-weight: bold;
}

@media screen and (max-width: 420px) {
    .btn-item input {
        flex-wrap: nowrap;
    }

    .btn-menu button.btn-photo.btn-photo {
        max-width: 110px;
        width: 100%;
        height: 40px;
        font-size: 0.85rem;
    }

    .btn-item {
        padding: 0;
    }
}
</style>
