<template>
    <section id="lp-blocks1" class="lp-blocks lp-blocks1 lp-blocks-rows-4">
        <header class="lp-section-header">
            <h2 class="lp-section-title">КОНТАКТЫ</h2>
        </header>
        <div class="lp-blocks-inside">
            <div class="page">
                <v-row>
                    <v-col md="4">
                        <div class="contact-info">
                            <h4>Компания "ВКС"</h4>
                            <div class="ml-2 single-contact-info">
                                <p class="txt">
                                             <v-icon
											 color="success"
											 size="20"
											 >mdi-phone-classic</v-icon>
											 &nbsp;
											 <a href="tel:+73472998989">+7 (347) 299-89-89</a>
                                </p>
                                <p class="txt">
                                             <v-icon
											 color="success"
											 size="20"
											 >mdi-phone-classic</v-icon>
											 &nbsp;
											 <a href="tel:+79871050805">+7 987 105-08-05</a>
                                </p>
                                <p class="txt">
                                             <v-icon
											 color="success"
											 size="20"
											 >mdi-phone-classic</v-icon>
											 &nbsp;
											 <a href="tel:+78612041612">+7 861 204-16-12</a>
                                </p>
                            </div>
                            <!-- /.single-contact-info -->
                            <div class="ml-2 single-contact-info">
                                <i class="far fa-at"></i>
                                <p class="txt">
                                             <v-icon
											 color="blue"
											 size="20"
											 >mdi-email</v-icon>
											 &nbsp;<a href="mailto:info@vksp.ru"
                                        >info@vksp.ru</a
                                    >
                                </p>
                            </div>
                            <!-- /.single-contact-info -->
                            <div class="ml-2 single-contact-info">
                                <i class="fas fa-home"></i>
                                <p class="txt">
                                             <v-icon
											 color="orange"
											 size="20"
											 >mdi-map-marker</v-icon>
											 &nbsp;
											 г. Уфа, ул. М. Жукова
                                    39 к. 1, офис 305
                                </p>
                            </div>

                            <div class="sidebar">
                                <div
                                    class="
                                        sidebar
                                        single-sidebar
                                        brochures-sidebar
                                    "
                                >
                                    <div class="sec-title">
                                        <h4>Карточка предприятия</h4>
                                    </div>
                                    <a class="ml-2" @click="open"
                                        ><i class="fas fa-download"></i>
                                        Реквизиты ООО "ВКС" (.doc)
                                    </a>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col v-if="!isMobile" cols="8">
                      <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=37453477623" width="760" height="400" frameborder="0"></iframe>
                       
                    </v-col>
                </v-row>
            </div>
        </div>
    </section>
</template>

<script>
import doc from "@/assets/doc/card.doc";
export default {
    data() {
        return {
            docs: "",
			isMobile: false
        };
    },
    mounted() {
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile);
        this.docs = doc;
    },
    methods: {
        open() {
            window.open(doc, "_blank");
        },
		checkIfMobile() {
		  this.isMobile = window.innerWidth <= 768; // Здесь 768px - пороговое значение для мобильных устройств, можно изменить по необходимости
		}
    },
    created() {
        this.init();
    },
	beforeDestroy() {
		window.removeEventListener('resize', this.checkIfMobile);
	},
};
</script>

<style></style>
