export default {
  light: {
    main: '#F3F3F3',  
    dark: '#222226',
    text: '#727272',
    primary: "#004990",
    primaryConst: "#536dfe",
    secondary: "#FFA500",
    secondaryConst: "#FF5C93",
    error: "#ff4040",
    warning: "#FFC260",
    success: "#04bf62",
    successConst: "#3CD4A0",
    info: "#9013FE",
    greyBold: '#4a4a4a',
    greyBoldConst: '#4a4a4a',
    greyMedium: '#6e6e6e',
    greyTint: '#b9b9b9',
    default: '#e0e0e0'
  },
  dark: {
    primary: "#536dfe",
    primaryConst: "#536dfe",
    secondary: "#FF5C93",
    secondaryConst: "#FF5C93",
    success: "#3CD4A0",
    successConst: "#3CD4A0",
    error: "#FF4081",
    greyMedium: '#bfbfbf',
    greyBold: '#d6d6d6',
    greyBoldConst: '#4a4a4a',
    greyTint: '#9b9b9b',
    default: '#e0e0e0',
  },
};

