export const words = [
    "!когда",
    "!что",
    "3d",
    "Chery",
    "Datsun",
    "Freightliner",
    "bluetooth",
    "блютус",
    "карта памяти",
    "наушники",
    "мышь",
    "клавиатура",
    "зарядка",
    "GAZEL",
    "Geely",
    "Granta",
    "Gucci",
    "Honda",
    "Hunter",
    "Hyundai",
    "Kalina",
    "Kamaz",
    "Kia",
    "LG",
    "Largus",
    "Man",
    "Maz",
    "Mercedes Benz",
    "Mitsubishi",
    "Nissan",
    "Peugeot",
    "Priora",
    "Renault",
    "Rio",
    "Solaris",
    "Volkswagen",
    "Volvo",
    "Xiaomi",
    "acer",
    "amd",
    "apple",
    "asus",
    "atlant",
    "audi",
    "bmw",
    "brother",
    "camry",
    "canon",
    "chevrolet",
    "ddr",
    "dell",
    "dexp",
    "dns",
    "epson",
    "faw",
    "galaxy",
    "geforce",
    "gigabyte",
    "gtx",
    "gtx 1060",
    "gtx 1080",
    "gtx 1660",
    "gtx 2060",
    "gtx 3060",
    "gtx 3070",
    "gtx 3080",
    "haier",
    "haval",
    "hifi",
    "home",
    "honor",
    "hp",
    "huawei",
    "indesit",
    "infiniti",
    "intel",
    "ipad",
    "iphone",
    "iveco",
    "kingston",
    "land rover",
    "lenovo",
    "mazda",
    "mersedes",
    "mining",
    "motorola",
    "nintendo",
    "notebook",
    "opel",
    "palit",
    "pantum",
    "philips",
    "playstation",
    "poco",
    "polaris",
    "pro max",
    "ps",
    "ps4",
    "radeon",
    "redmond",
    "rtx",
    "samsung",
    "skoda",
    "smart",
    "sony",
    "ssd",
    "subaru",
    "sunlight",
    "tefal",
    "toyota",
    "uaz",
    "vr",
    "wi fi",
    "wifi",
    "wildberries",
    "xbox",
    "xerox",
    "Автобус",
    "Апартамент",
    "Валдай",
    "Вентилятор",
    "Газель",
    "Гранта",
    "Гучи",
    "Гуччи",
    "Дом",
    "Еду",
    "Жилье",
    "Калина",
    "Камаз",
    "Костюм",
    "Ксиоми",
    "Лада",
    "Ларгус",
    "Лекарства",
    "Лифтбек",
    "Маз",
    "Ман",
    "Микроавтобус",
    "Минивен",
    "Минивэн",
    "Надгробие",
    "Памятник",
    "Помещение",
    "Препараты",
    "Приора",
    "Рио",
    "Свадьба",
    "Седан",
    "Солярис",
    "Студия",
    "Сумка",
    "Таблетки",
    "Тягач",
    "Уаз",
    "Участок",
    "Фольксваген",
    "Фредлайнер",
    "Фура",
    "Фургон",
    "Хендай",
    "Химия",
    "Хэтчбек",
    "авто",
    "автокредит",
    "автомашина",
    "автомобиль",
    "адаптер",
    "айпад",
    "айфон",
    "акустика",
    "алмаз",
    "амд",
    "асики",
    "ауди",
    "аудио",
    "без банка",
    "белое золото",
    "беретта",
    "беспроводной",
    "билайн",
    "биткоин",
    "блендер",
    "бмв",
    "браслет",
    "бриллиант",
    "бритва",
    "броши",
    "бытовая техника",
    "бычок",
    "ваз",
    "вайлдберриз",
    "валбери",
    "валберис",
    "веб камера",
    "велбери",
    "велберис",
    "веста",
    "весы",
    "вещи",
    "взять",
    "видео",
    "видеокабель",
    "видеокамера",
    "видеокарта",
    "видеоплеер",
    "видеотехника",
    "виртуальная реальность",
    "вольво",
    "вэлберис",
    "вэста",
    "гаджет",
    "гладкоствол",
    "гладкоствольное",
    "гриль",
    "датсун",
    "дача",
    "джили",
    "днс",
    "домашний",
    "дурак",
    "жемчуг",
    "жесткий диск",
    "запонки",
    "зачем",
    "зил",
    "золотая",
    "золотая цепочка",
    "золото",
    "ивеко",
    "игра",
    "игровой",
    "изумруд",
    "икс бокс",
    "имущество",
    "интел",
    "инфинити",
    "ипотека",
    "камри",
    "карта памяти",
    "квартира",
    "квику",
    "киа",
    "кингстон",
    "кинотеатр",
    "клавиатура",
    "колонки",
    "колье",
    "кольцо",
    "комната",
    "комплектующие",
    "компьютер",
    "контроллер",
    "копировальный",
    "криптовалюта",
    "куртка",
    "кэнон",
    "лазерный",
    "лексус",
    "лента",
    "лифан",
    "лэнд ровер",
    "мазда",
    "майнинг",
    "майнинг ферма",
    "маркет",
    "маршрутизатор",
    "материнская плата",
    "машина",
    "мегафон",
    "мерседес",
    "мерседес бенц",
    "микроволновая печь",
    "микроволновка",
    "микрокомпьютер",
    "митсубиси",
    "мобила",
    "мобильник",
    "монитор",
    "моноблок",
    "мтс",
    "музыкальный центр",
    "мультимедиа",
    "мфу",
    "мышь",
    "мясорубка",
    "накопитель",
    "нарезное",
    "наушники",
    "ндфл",
    "недвижимость",
    "нинтендо",
    "ниссан",
    "ноутбук",
    "обручалки",
    "обручальное",
    "одежда",
    "ожерелье",
    "опель",
    "оперативная память",
    "оплатить",
    "оружие",
    "отпариватель",
    "охолощенное",
    "очки",
    "память",
    "пароварка",
    "пежо",
    "пистолет",
    "планшет",
    "плата",
    "погасить",
    "подвески",
    "поко",
    "похороны",
    "почему",
    "принтер",
    "приставка",
    "про макс",
    "проводки",
    "продукты",
    "проектор",
    "процессор",
    "пс 4",
    "пс4",
    "пс5",
    "пылесос",
    "радио",
    "редмонд",
    "рено",
    "робот",
    "роутер",
    "рыба",
    "самосвал",
    "санлайт",
    "сапфир",
    "саундбар",
    "седельный",
    "сервер",
    "серебро",
    "серебряная",
    "серьги",
    "системный блок",
    "сканер",
    "смарт",
    "смартфон",
    "соковыжималка",
    "сони",
    "спортмастер",
    "справка",
    "ствол",
    "стиралка",
    "стиральная машина",
    "стиральная машинка",
    "струйный",
    "субару",
    "сузуки",
    "таджикистан",
    "тайота",
    "тв",
    "телевизор",
    "телефон",
    "тефаль",
    "тостер",
    "триммер",
    "узбекистан",
    "украшение",
    "утюг",
    "фен",
    "ферма",
    "флешка",
    "форд",
    "фото",
    "фотоаппарат",
    "хавал",
    "холодильник",
    "хонда",
    "хонор",
    "хоум",
    "хуавей",
    "хуавэй",
    "хундай",
    "цепочка",
    "цепь",
    "цифровой",
    "чери",
    "шевроле",
    "шевролет",
    "шкода",
    "шлем",
    "штатив",
    "шуруповерт",
    "ювелир",
    "яндекс",
];
