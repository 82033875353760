<template>
    <div class="credit">
        <div class="credit__container">
            <div class="greyBold--text credit__wrap">
                <p :class="sm ? 'text-h5' : 'text-h4'">
                   Ой. Страница не найдена.
                </p>
                <router-link to="/">
                    <v-btn color="primary" x-large width="300" class="mt-10">
                        Вернуться на сайт
                    </v-btn>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted() {},
    computed: {
        sm() {
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
        },
    },
};
</script>

<style src="./Credit.scss" lang="scss"></style>
